<template>
    <div>
        <div class="main pt-12" v-if="status == 'error'">
            <svg t="1654873679470" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="2540" width="200" height="200">
                <path
                    d="M512 915.911111a398.222222 398.222222 0 1 1 398.222222-398.222222 398.222222 398.222222 0 0 1-398.222222 398.222222z m0-740.352A342.129778 342.129778 0 1 0 854.129778 517.688889 342.471111 342.471111 0 0 0 512 175.559111z"
                    fill="#FF9585" p-id="2541"></path>
                <path d="M214.926222 775.259022l554.552889-554.552889 39.674311 39.651556-554.564266 554.564267z"
                    fill="#FF9585" p-id="2542"></path>
            </svg>
            <p class="text heading">Google登录失败</p>
            <p class="text subheading">{{ this.info }}</p>
            <p class="text"><v-btn rounded outlined color="error" dark to="/">返回首页</v-btn></p>
        </div>

        <div class="main pt-12" v-if="status == 'bindError'">
            <svg t="1654873679470" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="2540" width="200" height="200">
                <path
                    d="M512 915.911111a398.222222 398.222222 0 1 1 398.222222-398.222222 398.222222 398.222222 0 0 1-398.222222 398.222222z m0-740.352A342.129778 342.129778 0 1 0 854.129778 517.688889 342.471111 342.471111 0 0 0 512 175.559111z"
                    fill="#FF9585" p-id="2541"></path>
                <path d="M214.926222 775.259022l554.552889-554.552889 39.674311 39.651556-554.564266 554.564267z"
                    fill="#FF9585" p-id="2542"></path>
            </svg>
            <p class="text heading">Google登录失败</p>
            <p class="text subheading">{{ this.info }}</p>
            <p class="text"><v-btn rounded outlined color="error" dark to="/">返回首页</v-btn></p>
        </div>

        <div class="main pt-12" v-if="status == 'success'">
            <v-img class="mx-auto"
                lazy-src="https://web-static-1304188470.file.myqcloud.com/img/google_logo_animation_lazy.jpg"
                max-height="150" max-width="250"
                src="https://web-static-1304188470.file.myqcloud.com/img/google_logo_animation.gif"
                maxWidth="400px"></v-img>
            <p class="text heading">登录成功</p>
            <p class="text subheading">即将跳转...</p>
        </div>

        <div class="main pt-12" v-if="status == 'bindSuccess'">
            <v-img class="mx-auto"
                lazy-src="https://web-static-1304188470.file.myqcloud.com/img/google_logo_animation_lazy.jpg"
                max-height="150" max-width="250"
                src="https://web-static-1304188470.file.myqcloud.com/img/google_logo_animation.gif"
                maxWidth="400px"></v-img>
            <p class="text heading">绑定成功</p>
            <p class="text subheading">即将跳转...</p>
        </div>

        <div class="main pt-12" v-if="status == 'unbindSuccess'" justify="center" align="center">
            <svg t="1654874371713" class="icon" viewBox="0 0 1030 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="3155" width="200" height="200">
                <path
                    d="M48.232882 396.433184h164.776629a40.224883 40.224883 0 0 0 29.078229-12.115928 40.70952 40.70952 0 0 0-29.078229-69.787749H48.232882a41.194157 41.194157 0 0 0 0 81.903677z m129.882755-165.745903a40.70952 40.70952 0 0 0 29.078228 11.631291 40.70952 40.70952 0 0 0 29.078229-69.787749l-116.312915-116.312914a41.194157 41.194157 0 0 0-58.156457 58.156457z m369.778141 457.012827L345.800089 891.732346A149.268241 149.268241 0 1 1 132.559745 678.492002l204.032238-202.093689a41.194157 41.194157 0 0 0-58.156457-58.156457L74.403288 622.274094a231.656555 231.656555 0 1 0 327.130072 327.614709l204.032238-204.032238a41.194157 41.194157 0 0 0-58.156457-58.156457z m121.643923 83.357589a40.70952 40.70952 0 0 0-41.194157 41.194157v164.776629a41.194157 41.194157 0 0 0 81.903677 0v-164.776629a40.224883 40.224883 0 0 0-12.115928-29.078228 41.194157 41.194157 0 0 0-28.593592-12.115929z m313.560233-149.752878h-164.776629a40.224883 40.224883 0 0 0-29.078229 12.115929 40.70952 40.70952 0 0 0 29.078229 69.787749h164.776629a41.194157 41.194157 0 0 0 0-81.903678z m-129.882755 165.745904a40.70952 40.70952 0 0 0-29.078229-11.631292 40.70952 40.70952 0 0 0-29.078228 69.787749l116.312914 116.312915a41.194157 41.194157 0 1 0 58.156458-58.156458zM361.308477 246.680307a40.70952 40.70952 0 0 0 41.194158-41.194158V41.194157a41.194157 41.194157 0 0 0-81.903678 0v164.776629a40.224883 40.224883 0 0 0 12.115929 29.078229 41.194157 41.194157 0 0 0 28.593591 11.631292z m594.649777-178.34647a231.656555 231.656555 0 0 0-327.61471 0L424.311306 272.366075a41.194157 41.194157 0 0 0 58.156458 58.156458L685.04609 126.005658a149.752878 149.752878 0 0 1 256.857687 105.650897A147.814329 147.814329 0 0 1 897.801796 339.246001l-204.032237 204.032238a41.194157 41.194157 0 0 0 58.156457 58.156457l204.032238-204.032238a231.656555 231.656555 0 0 0 0-329.068621z"
                    fill="#0BA35E" p-id="3156"></path>
            </svg>
            <p class="text heading">解绑成功</p>
            <p class="text"><v-btn rounded outlined color="error" dark to="/">返回首页</v-btn></p>
        </div>

        <div class="main pt-12" v-if="status == 'unbindError'" justify="center" align="center">
            <svg t="1654874371713" class="icon" viewBox="0 0 1030 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                p-id="3155" width="200" height="200">
                <path
                    d="M48.232882 396.433184h164.776629a40.224883 40.224883 0 0 0 29.078229-12.115928 40.70952 40.70952 0 0 0-29.078229-69.787749H48.232882a41.194157 41.194157 0 0 0 0 81.903677z m129.882755-165.745903a40.70952 40.70952 0 0 0 29.078228 11.631291 40.70952 40.70952 0 0 0 29.078229-69.787749l-116.312915-116.312914a41.194157 41.194157 0 0 0-58.156457 58.156457z m369.778141 457.012827L345.800089 891.732346A149.268241 149.268241 0 1 1 132.559745 678.492002l204.032238-202.093689a41.194157 41.194157 0 0 0-58.156457-58.156457L74.403288 622.274094a231.656555 231.656555 0 1 0 327.130072 327.614709l204.032238-204.032238a41.194157 41.194157 0 0 0-58.156457-58.156457z m121.643923 83.357589a40.70952 40.70952 0 0 0-41.194157 41.194157v164.776629a41.194157 41.194157 0 0 0 81.903677 0v-164.776629a40.224883 40.224883 0 0 0-12.115928-29.078228 41.194157 41.194157 0 0 0-28.593592-12.115929z m313.560233-149.752878h-164.776629a40.224883 40.224883 0 0 0-29.078229 12.115929 40.70952 40.70952 0 0 0 29.078229 69.787749h164.776629a41.194157 41.194157 0 0 0 0-81.903678z m-129.882755 165.745904a40.70952 40.70952 0 0 0-29.078229-11.631292 40.70952 40.70952 0 0 0-29.078228 69.787749l116.312914 116.312915a41.194157 41.194157 0 1 0 58.156458-58.156458zM361.308477 246.680307a40.70952 40.70952 0 0 0 41.194158-41.194158V41.194157a41.194157 41.194157 0 0 0-81.903678 0v164.776629a40.224883 40.224883 0 0 0 12.115929 29.078229 41.194157 41.194157 0 0 0 28.593591 11.631292z m594.649777-178.34647a231.656555 231.656555 0 0 0-327.61471 0L424.311306 272.366075a41.194157 41.194157 0 0 0 58.156458 58.156458L685.04609 126.005658a149.752878 149.752878 0 0 1 256.857687 105.650897A147.814329 147.814329 0 0 1 897.801796 339.246001l-204.032237 204.032238a41.194157 41.194157 0 0 0 58.156457 58.156457l204.032238-204.032238a231.656555 231.656555 0 0 0 0-329.068621z"
                    fill="#FF9585" p-id="3156"></path>
            </svg>
            <p class="text heading">解绑失败</p>
            <p class="text subheading">{{ this.info }}</p>
            <p class="text"><v-btn rounded outlined color="error" dark to="/">返回首页</v-btn></p>
        </div>
    </div>
</template>
  
<script>
export default {
    name: "googleCallback",
    data: function () {
        return {
            status: "success",
            info: "",
            callback:"",
        };
    },
    mounted: function () {
        if (this.$route.params.status != "") {
            this.status = this.$route.params.status;
        }
        if (this.$route.query.info != "") {
            this.info = this.$route.query.info;
        }

        //后续逻辑
        if (this.status == "success"||this.status=="bindSuccess") {
            setTimeout(() => {
                if(this.callback==""){
                    this.$router.push("/");
                }else{
                    this.$router.push(this.callback);
                }
            }, 2500);
        }
    },
};
</script>
<style>
.main {
    padding: 10% 0 40px;
}

.icon {
    display: block;
    width: 20%;
    height: 20%;
    margin: 0 auto 20px;
}

.text {
    margin: 0;
    text-align: center;
}

.heading {
    color: #3d3d3d;
    font-weight: 400;
    font-size: 50px;
}

.subheading {
    margin-top: 20px;
    font-weight: 100;
}
</style>
  